const Logo = () => (
  <svg
    id="Grupo_176"
    data-name="Grupo 176"
    xmlns="http://www.w3.org/2000/svg"
    width="112"
    height="27.571"
    viewBox="0 0 112 27.571"
  >
    <g id="Grupo_174" data-name="Grupo 174" transform="translate(36.902 3.742)">
      <g id="Grupo_173" data-name="Grupo 173">
        <path
          id="Caminho_122"
          data-name="Caminho 122"
          d="M1478.709,408.478c2.9,0,4.535,1.78,4.535,5a10.272,10.272,0,0,1-.171,1.926h-6.315a2.2,2.2,0,0,0,2.438,2.292,10.278,10.278,0,0,0,3.1-.634l.8,2.341a10.44,10.44,0,0,1-4,.829c-3.438,0-5.291-1.9-5.291-5.827C1473.808,410.6,1475.539,408.478,1478.709,408.478Zm1.682,4.682c-.049-1.414-.585-2.146-1.755-2.146-1.244,0-1.756.829-1.878,2.146Z"
          transform="translate(-1451.327 -404.278)"
          fill="#150d21"
        />
        <path
          id="Caminho_123"
          data-name="Caminho 123"
          d="M1510.691,408.722h2.048l.317,1.268a4.167,4.167,0,0,1,3.219-1.512,8.033,8.033,0,0,1,1.317.122l-.2,2.633a8.106,8.106,0,0,0-1.219-.1,3.433,3.433,0,0,0-2.609.927v7.924h-2.877Z"
          transform="translate(-1476.458 -404.278)"
          fill="#150d21"
        />
        <path
          id="Caminho_124"
          data-name="Caminho 124"
          d="M1534.8,405.483c0-3.974,1.731-5.974,4.852-5.974a11.425,11.425,0,0,1,1.926.2v-4.389h2.877v15.7h-2.073l-.293-.756a4.308,4.308,0,0,1-2.877,1C1536.479,411.262,1534.8,409.409,1534.8,405.483Zm6.778,2.78v-6.12a8.493,8.493,0,0,0-1.56-.171c-1.731,0-2.292.976-2.292,3.463,0,2.414.512,3.34,1.975,3.34A3.767,3.767,0,0,0,1541.574,408.263Z"
          transform="translate(-1492.882 -395.31)"
          fill="#150d21"
        />
        <path
          id="Caminho_125"
          data-name="Caminho 125"
          d="M1577.115,408.478c2.9,0,4.535,1.78,4.535,5a10.272,10.272,0,0,1-.171,1.926h-6.315a2.2,2.2,0,0,0,2.438,2.292,10.278,10.278,0,0,0,3.1-.634l.8,2.341a10.437,10.437,0,0,1-4,.829c-3.438,0-5.291-1.9-5.291-5.827C1572.214,410.6,1573.945,408.478,1577.115,408.478Zm1.682,4.682c-.049-1.414-.585-2.146-1.755-2.146-1.244,0-1.756.829-1.878,2.146Z"
          transform="translate(-1518.378 -404.278)"
          fill="#150d21"
        />
        <path
          id="Caminho_126"
          data-name="Caminho 126"
          d="M1605.654,409.243h2.877c.683,2.853,1.56,6.144,2.414,8.68h.1c.854-2.536,1.731-5.828,2.39-8.68h2.828a89.038,89.038,0,0,1-3.414,11.265h-3.779A88.939,88.939,0,0,1,1605.654,409.243Z"
          transform="translate(-1541.163 -404.799)"
          fill="#150d21"
        />
        <path
          id="Caminho_127"
          data-name="Caminho 127"
          d="M1446.707,409.232l-2.374,9.016a5.285,5.285,0,0,1-1.143.09c-1.195,0-1.634-.634-1.634-1.731v-7.374h-2.877v7.74c0,2.195,1.1,3.9,3.755,3.9a5.366,5.366,0,0,0,1.246-.144l-1.155,4.387h2.877l4.182-15.884Z"
          transform="translate(-1427.391 -404.792)"
          fill="#150d21"
        />
        <path
          id="Caminho_128"
          data-name="Caminho 128"
          d="M1403.253,395.3h2.877v4.925a5.817,5.817,0,0,1,2.829-.731c2.658,0,3.755,1.707,3.755,3.9V411h-2.877v-7.242c0-1.1-.439-1.731-1.634-1.731a4.9,4.9,0,0,0-2.073.536V411h-2.877Z"
          transform="translate(-1403.253 -395.296)"
          fill="#150d21"
        />
      </g>
    </g>
    <g id="Grupo_175" data-name="Grupo 175">
      <path
        id="Caminho_129"
        data-name="Caminho 129"
        d="M1315,383.552l-24.977,0s-.051,0-.076,0a2.5,2.5,0,0,0-2.508,2.5v12.589a7.451,7.451,0,0,0,7.45,7.453h9.441a1.891,1.891,0,0,1,1.9,1.854c0,.949-.475,1.278-.475,1.947a1.187,1.187,0,0,0,1.115,1.22c3.2,0,5.216-3.935,5.672-5.643,1.128-4.219,4.238-16.094,4.815-18.293a4.256,4.256,0,0,0,.146-1.128A2.5,2.5,0,0,0,1315,383.552Zm-10.2,6.937a1.539,1.539,0,1,1-1.539,1.539A1.538,1.538,0,0,1,1304.8,390.489Zm-6.561.032a1.539,1.539,0,1,1-1.539,1.539A1.54,1.54,0,0,1,1298.237,390.52Zm11.792,5.691a12.156,12.156,0,0,1-17.021,0l-.115-.112,1.52-1.549.111.112a9.99,9.99,0,0,0,13.985,0l.115-.112,1.517,1.549Z"
        transform="translate(-1287.438 -383.552)"
        fill="#150d21"
      />
    </g>
  </svg>
);

export default Logo;
