import styled from 'styled-components';

export const PageWrapper = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  max-width: 100vw;

  height: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  background: ${({ theme }) => theme.background};
  transition: background 0.15s linear;

  overflow: ${({ isOpen }) => {
    return isOpen ? 'hidden' : 'initial';
  }};
`;

export const PageContainer = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  max-width: 1440px;

  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1088px) {
    padding: 0 32px;
  }

  @media (max-width: 500px) {
    padding: 0 20px;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1024px;

  height: fit-content;

  display: flex;
  justify-content: center;
`;

export const IndexEmpresasWrapper = styled.div`
  margin-top: 102px;
  width: 100%;
  max-width: 100vw;

  @media (max-width: 650px) {
    margin-top: 30px;
  }
`;

export const NotificacoesEmpresasWrapper = styled.div`
  margin-top: 55px;
  width: 100%;
  max-width: 100vw;

  @media (max-width: 650px) {
    margin-top: 30px;
  }
`;

export const ChatbotEmpresasWrapper = styled.div`
  margin-top: 120px;
  width: 100%;
  max-width: 100vw;

  @media (max-width: 650px) {
    margin-top: 30px;
  }
`;
