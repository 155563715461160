import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const Container = styled.div`
  margin-top: 60px;

  display: flex;

  width: 100%;
  align-items: center;

  p {
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.footer.text};
    transition: 0.15s linear;

    margin-left: 48px;
    text-align: center;
  }

  a.logo {
    svg {
      * {
        fill: ${({ theme }) => theme.footer.logo};
        transition: 0.15s linear;
      }
    }
  }

  @media (max-width: 875px) {
    flex-direction: column;
    gap: 30px;

    img {
      order: 1;
    }

    p {
      order: 4;
      margin-left: 0;
    }
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  max-width: 150px;
  position: relative;
  display: flex;
  align-items: center;

  svg {
    * {
      transition: 0.15s linear;
      color: ${({ theme }) => theme.footer.text};
    }
  }

  select {
    width: 100%;
    border: none;
    outline: none;
    height: 36px;
    padding: 10px 35px;
    color: ${({ theme }) => theme.footer.text};
    z-index: 1;

    option {
      color: ${colors.gray500};
    }

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background: none;

    border: 1px solid ${colors.gray300};
    border-radius: 5px;
    font-size: 14px;
    line-height: 14px;
  }

  .arrow {
    position: absolute;
    right: 6px;
    color: ${colors.gray500};
  }

  .theme {
    position: absolute;
    left: 10px;
    color: ${colors.gray500};
  }

  @media (max-width: 875px) {
    order: 2;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  margin: 0 auto;

  a {
    color: ${({ theme }) => theme.footer.text};
    transition: 0.2s linear;
    cursor: pointer;

    :hover {
      color: ${colors.purple400};
    }
  }

  hr {
    border: none;
    width: 1px;
    height: 24px;
    background: #707070;
    margin: 0 16px;
  }

  @media (max-width: 875px) {
    order: 3;
  }
`;
