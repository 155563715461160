import Link from 'next/link';
import { useSettings } from '../../Providers/Footer';

import { Container, Column, Wrapper } from './styles';

import Image from 'next/image';
import seloDark from '@/images/Footer/lgpd-dk.png';
import seloLight from '@/images/Footer/lgpd-lt.png';
import { useTheme } from '../../Providers/Theme';

const FooterTop: React.FC = () => {
  const { currentTheme } = useTheme();

  const { settings } = useSettings();
  return (
    <Wrapper>
      <Container>
        <Column>
          <h3>Serviços</h3>
          <ul>
            <li>
              <Link href="https://sacflow.io" passHref>
                <a>Sacflow</a>
              </Link>
            </li>
            <li>
              <Link href="/notificacoes" passHref>
                <a>Notificações</a>
              </Link>
            </li>
            <li>
              <Link href="/aplicativos" passHref>
                <a>Área do Cliente</a>
              </Link>
            </li>
            <li>
              <Link href="/sites" passHref>
                <a>Criação de sites</a>
              </Link>
            </li>
          </ul>
        </Column>
        <Column>
          <h3>Empresa</h3>
          <ul>
            <li>
              <Link href="/a-empresa#o-que-fazemos" passHref>
                <a>O que fazemos</a>
              </Link>
            </li>
            <li>
              <Link href="/a-empresa#valores" passHref>
                <a>Valores</a>
              </Link>
            </li>
            <li>
              <Link href="/a-empresa#equipe" passHref>
                <a>Equipe</a>
              </Link>
            </li>
            <li>
              <Link href="/a-empresa#nossa-missao" passHref>
                <a>Nossa missão</a>
              </Link>
            </li>
            <li>
              <Link href="/marca" passHref>
                <a>Marca</a>
              </Link>
            </li>
          </ul>
        </Column>
        <Column>
          <h3>Contato</h3>
          <ul>
            <li>
              <a href={settings.instagram} target="_blank" rel="noreferrer">
                Instagram
              </a>
            </li>
            <li>
              <a
                href={settings.whatsapp}
                target="_blank"
                rel="noopener noreferrer"
              >
                WhatsApp
              </a>
            </li>
          </ul>
        </Column>
      </Container>

      <a href="https://www.privacidade.com.br/portal-de-privacidade?token=41e9e89ed3e6d18b3e3d921a78284e77">
        <Image
          src={currentTheme === 'light' ? seloLight : seloDark}
          alt="Selo de canal de comunicação LGPD"
          width={150}
          height={125}
          quality={100}
        />
      </a>
    </Wrapper>
  );
};

export default FooterTop;
