import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const Container = styled.div`
  width: 100%;
  display: flex;
  max-width: 650px;
  justify-content: space-between;

  @media (max-width: 750px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 40px 0;
  @media (max-width: 750px) {
    display: none;
  }
`;

export const Column = styled.div`
  h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: ${({ theme }) => theme.footer.text};
    transition: 0.15s linear;
  }

  a {
    color: ${({ theme }) => theme.footer.link};
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    transition: 0.15s linear;

    :hover {
      color: ${colors.purple400};
    }
  }

  ul {
    margin-top: 36px;
  }

  li:not(:first-child) {
    margin-top: 20px;
  }
`;
