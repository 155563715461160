import React from 'react';
import Head from 'next/head';

import { SITE_URL } from '@/config';

interface ISEO {
  title: string;
  description: string;
  image?: string;
}

const SEO = ({
  title,
  description,
  image = '/open-graph.png',
}: ISEO): JSX.Element => {
  const seo = {
    title: title || '',
    description:
      description ||
      'Desenvolvemos soluções inteligentes para transportadoras 🚚🚀',
    image: `${SITE_URL}${image}`,
  };

  return (
    <Head>
      <title>{title + ' · Hyerdev'}</title>
      <meta name="title" content={title + ' · Hyerdev'} />
      <meta name="description" content={seo.description} />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="keywords"
        content="Hyerdev, sites para transportadoras, apps para transportas, chatbot para transportadora, notificações via WhatsApp"
      />
      <meta name="author" content="@hyerdev" />
      <meta name="image" content={seo.image} />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />

      {/* FACEBOOK OPEN GRAPH */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://hyerdev.com.br" />
      <meta property="og:title" content={title + ' · Hyerdev'} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:locale" content="pt_BR" />

      {/* TWITTER */}
      <meta property="twitter:card" content={seo.image} />
      <meta property="twitter:url" content="https://hyerdev.com.br" />
      <meta property="twitter:title" content={title + ' · Hyerdev'} />
      <meta property="twitter:description" content={seo.description} />
      <meta property="twitter:image" content={seo.image}></meta>
    </Head>
  );
};

export default SEO;
