import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  hr {
    height: 1px;
    width: 100%;
    border: none;
    background: #dddddd;
    margin: 15px 0;
  }
`;

export const AccordionHeader = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 20px 0;

  > * {
    color: ${({ theme }) => theme.text};
    transition: 0.15s linear;
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: ${({ theme }) => theme.text};
    transition: 0.15s linear;
  }
`;

export const AccordionBody = styled.div`
  @keyframes dropdown {
    from {
      max-height: 0;
    }
    to {
      max-height: 300px;
    }
  }

  animation: dropdown 0.5s linear;
  overflow: hidden;

  ul {
    margin-top: 5px;
  }
  li:not(:first-child) {
    margin-top: 15px;
  }

  a {
    font-size: 16px;
    line-height: 18px;
    color: ${({ theme }) => theme.footer.link};
    transition: 0.15 linear;
  }
`;
