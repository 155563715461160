import Link from 'next/link';
import { Container } from './styles';
import Accordion from './Accordion';
import { useSettings } from '@/components/global/Providers/Footer';
import Image from 'next/image';

import seloDark from '@/images/Footer/lgpd-dk.png';
import seloLight from '@/images/Footer/lgpd-lt.png';
import { useTheme } from '@/components/global/Providers/Theme';

const Mobile: React.FC = () => {
  const { settings } = useSettings();
  const { currentTheme } = useTheme();

  return (
    <Container>
      <Accordion title="Serviços">
        <ul>
          <li>
            <Link href="/chatbots" passHref>
              <a>Chatbots</a>
            </Link>
          </li>
          <li>
            <Link href="/notificacoes" passHref>
              <a>Notificações</a>
            </Link>
          </li>
          <li>
            <Link href="/aplicativos" passHref>
              <a>Aplicativos</a>
            </Link>
          </li>
          <li>
            <Link href="/sites" passHref>
              <a>Sites</a>
            </Link>
          </li>
        </ul>
      </Accordion>
      <Accordion title="Empresa">
        <ul>
          <li>
            <Link href="/a-empresa#o-que-fazemos" passHref>
              <a>O que fazemos</a>
            </Link>
          </li>
          <li>
            <Link href="/a-empresa#valores" passHref>
              <a>Valores</a>
            </Link>
          </li>
          <li>
            <Link href="/a-empresa#equipe" passHref>
              <a>Equipe</a>
            </Link>
          </li>
          <li>
            <Link href="/a-empresa#nossa-missao" passHref>
              <a>Nossa missão</a>
            </Link>
          </li>
          <li>
            <Link href="/marca" passHref>
              <a>Marca</a>
            </Link>
          </li>
        </ul>
      </Accordion>
      <Accordion title="Contato">
        <ul>
          <li>
            <a href={settings.instagram} target="_blank" rel="noreferrer">
              Instagram
            </a>
          </li>
          <li>
            <a
              href={settings.whatsapp}
              target="_blank"
              rel="noopener noreferrer"
            >
              WhatsApp
            </a>
          </li>
          {/* <li>
            <Link href="/a-empresa#equipe" passHref>
              <a>Trabalhe conosco</a>
            </Link>
          </li> */}
        </ul>
      </Accordion>

      <div style={{ paddingTop: '40px' }}>
        <a href="https://www.privacidade.com.br/portal-de-privacidade?token=41e9e89ed3e6d18b3e3d921a78284e77">
          <Image
            src={currentTheme === 'light' ? seloLight : seloDark}
            alt="Selo de verificado"
            width={150}
            height={125}
            quality={100}
          />
        </a>
      </div>
    </Container>
  );
};

export default Mobile;
