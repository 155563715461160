import { useState } from 'react';

import { Container, AccordionHeader, AccordionBody } from './styles';
import { BiPlus, BiMinus } from 'react-icons/bi';

interface IProps {
  title: string;
}

const Accordion: React.FC<IProps> = ({ children, title }) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <AccordionHeader onClick={() => setOpen(!open)}>
        <h4>{title}</h4> {!open ? <BiPlus /> : <BiMinus />}
      </AccordionHeader>
      {open && <AccordionBody>{children}</AccordionBody>}
      <hr />
    </Container>
  );
};

export default Accordion;
