import { useState, createContext, useMemo, useContext, useEffect } from 'react';

interface IValue {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderMobileContext = createContext({} as IValue);

export const useHeaderMobile = (): IValue => useContext(HeaderMobileContext);

const HeaderMobileProvider: React.FC = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const [windowSize, setWindowSize] = useState(0);

  const value = useMemo(() => {
    return {
      isOpen,
      setOpen,
    };
  }, [isOpen, setOpen]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => {
        setWindowSize(window.innerWidth);
      });

      return window.removeEventListener('resize', () => {
        setWindowSize(window.innerWidth);
      });
    }
  }, []);

  useEffect(() => {
    if (windowSize > 920) {
      setOpen(false);
    }
  }, [windowSize]);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      isOpen
        ? document.body.classList.add('modal-open')
        : document.body.classList.remove('modal-open');
    }
  }, [isOpen]);

  return (
    <HeaderMobileContext.Provider value={value}>
      {children}
    </HeaderMobileContext.Provider>
  );
};

export default HeaderMobileProvider;
