import FooterTop from './FooterTop';
import FooterTopMobile from './FooterTop/Mobile';
import FooterBottom from './FooterBottom';
import { FooterWrapper, FooterContainer } from './styles';

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterTop />
        <FooterTopMobile />
        <FooterBottom />
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
