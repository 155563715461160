import React, { useMemo, useState } from 'react';
import Link from 'next/link';

import { Container, SelectContainer, SocialMedia } from './styles';

import { AiOutlineInstagram, AiFillYoutube } from 'react-icons/ai';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { BiSun, BiMoon } from 'react-icons/bi';
import { RiComputerLine } from 'react-icons/ri';

import { useTheme } from '@/components/global/Providers/Theme';
import Logo from '@/images/Footer/Logo';
import { useSettings } from '../../Providers/Footer';

const FooterBottom: React.FC = () => {
  const [theme, setTheme] = useState('dark');
  const { settings } = useSettings();

  const { updatePreference, preference } = useTheme();

  const icon = useMemo(() => {
    switch (theme) {
      case 'system':
        return <RiComputerLine className="theme" />;
      case 'light':
        return <BiSun className="theme" />;
      case 'dark':
        return <BiMoon className="theme" />;
    }
  }, [theme]);

  return (
    <Container>
      <Link href="/">
        <a className="logo" aria-label="Logo da Hyerdev">
          <Logo aria-label="Logo da Hyerdev" />
        </a>
      </Link>
      <p>
          Hyerdev Software Development LTDA - CNPJ: 39.805.323/0001-98
      </p>
      <SocialMedia>
        <a
          href={settings.instagram || 'https://www.instagram.com/hyerdev'}
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineInstagram size={24} aria-label="Ícone do Instagram" />
        </a>
        <hr />
        <a
          href={
            settings.youtube ||
            'https://www.youtube.com/channel/UCTuKDeqT-HigFceOU1HM6Zw'
          }
          target="_blank"
          rel="noreferrer noopener"
        >
          <AiFillYoutube size={24} aria-label="Ícone do YouTube" />
        </a>
      </SocialMedia>
      <SelectContainer>
        {icon}
        <select
          onChange={(e) => {
            updatePreference(e.target.value);
            setTheme(e.target.value);
          }}
          defaultValue={preference}
        >
          <option value="light">Claro</option>
          <option value="dark">Escuro</option>
          <option value="system">Sistema</option>
        </select>
        <MdKeyboardArrowDown className="arrow" size={20} />
      </SelectContainer>
    </Container>
  );
};

export default FooterBottom;
