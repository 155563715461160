import { createGlobalStyle } from 'styled-components';

export const colors = {
  blue: '#468fff',
  lightBlue: '#f4f8fc',

  green: '#29d3a6',
  yellow: '#ffaf5e',
  red: '#ff2e6e',

  lightFooter: '#f9f9f9',
  darkFooter: '#180F27',

  gray200: '#e5e5e5',
  gray300: '#c1c1c1',
  gray400: '#929292',
  gray500: '#747474',
  gray600: '#5a5a5a',
  gray700: '#150d21',

  purple100: '#e5cfff',
  purple200: '#c788ff',
  purple300: '#8623d5',
  purple400: '#6d0fc6',
  purple500: '#5000a2',
  purple600: '#39007f',
};

export const darkTheme = {
  text: '#ffffff',
  background: colors.gray700,

  index: {
    chatbot: '#081934',
    notificacoes: '#2B0410',
    aplicativos: '#261503',
    sites: '#021C15',
  },

  chatbots: {
    heroSection: {
      shadow: '#FFFFFF',
    },

    roteiros: {
      background: '#200D40',
    },
  },

  notificacoes: {
    vacuo: {
      title: colors.purple200,
    },

    relatorios: {
      shadow: 'rgba(0,0,0,0.42)',
      title: colors.purple100,
      background: '#200D40',
    },

    personalizavel: {
      notification: colors.gray700 + 'aa',
      icon: colors.purple300,
      border: 'rgba(255, 255, 255, 0.1)',
    },
  },

  clientCases: {
    background: '#200D40',
    text: '#ffffff',
  },

  footer: {
    background: colors.darkFooter,
    link: '#E5CFFF',
    text: '#ffffff',
    logo: '#ffffff',
  },

  aplicativos: {
    novosAplicativos: {
      bottomBorder: 'rgba(255,255,255,0.08)',
    },

    heroSection: {
      api: {
        background: colors.purple400,
        text: '#ffffff',
      },
    },

    pontuacao: {
      background: '#200D40',
      title: `linear-gradient(-45deg, #9919FF 20%, #9919FF, #AB43FF 40%, #C788FF 70%)`,
      border: 'rgba(255,255,255,0.1)',
      shadow: 'rgba(255,255,255,0.16)',
      circlesBackground: '#171717',
      bottomDisclaimerText: '#707070',
    },

    integracao: {
      button: colors.purple200,
    },
  },

  notFound: {
    title: colors.purple200,
  },

  header: {
    background: colors.gray700,
    text: '#ffffff',

    button: {
      text: '#ffffff',
      background: colors.purple300,

      hover: colors.purple400,
    },
  },

  appRastreamento: {
    background: '#200D40',
    title: '#ffffff',
  },

  sites: {
    designers: {
      background: '#200D40',
    },
    stepper: {
      color: colors.purple200,
    },
    relatorios: {
      background: '#200D40',
    },
  },
};

export const lightTheme = {
  text: colors.gray700,
  background: '#ffffff',

  index: {
    chatbot: '#F4F8FC',
    notificacoes: '#FFF2F6',
    aplicativos: '#FFF6EE',
    sites: '#E8FCF7',
  },

  chatbots: {
    heroSection: {
      shadow: '#000000',
    },

    roteiros: {
      background: colors.lightBlue,
    },
  },

  notFound: {
    title: colors.purple600,
  },

  aplicativos: {
    heroSection: {
      api: {
        background: colors.purple100,
        text: colors.purple600,
      },
    },

    novosAplicativos: {
      bottomBorder: 'rgba(0,0,0,0.08)',
    },

    pontuacao: {
      background: colors.lightBlue,
      title: `linear-gradient(-45deg, ${colors.purple300} 15% , ${colors.purple300} 50%, ${colors.purple500}  , ${colors.purple600} 95%)`,
      border: 'rgba(0,0,0,0.1)',
      shadow: 'rgba(0,0,0,0.16)',
      circlesBackground: '#ffffff',
      bottomDisclaimerText: '#929292',
    },

    integracao: {
      button: colors.purple300,
    },
  },

  notificacoes: {
    vacuo: {
      title: colors.purple500,
    },

    relatorios: {
      shadow: '#002E741F',
      title: colors.purple600,
      background: colors.lightBlue,
    },

    personalizavel: {
      notification: '#ffffffB3',
      icon: colors.purple500,
      border: 'rgba(0,0,0,0.1)',
    },
  },

  clientCases: {
    background: colors.lightBlue,
    text: colors.gray700,
  },

  footer: {
    background: '#f9f9f9',
    link: '#747474',
    text: '#747474',
    logo: colors.gray700,
  },

  header: {
    background: '#ffffff',
    text: colors.gray700,

    button: {
      background: '#dbcdee',
      text: colors.purple600,

      hover: '#c788ff77',
    },
  },

  appRastreamento: {
    background: colors.lightBlue,
    title: colors.purple600,
  },

  sites: {
    designers: {
      background: colors.lightBlue,
    },

    stepper: {
      color: colors.purple300,
    },

    relatorios: {
      background: colors.lightBlue,
    },
  },
};

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    -webkit-tap-highlight-color: transparent;
    margin: 0;
    padding: 0;
    scroll-margin-top: 70px;
  }

  img {
    pointer-events: none;
    user-select: none;
  }

  a {
    text-decoration:none;
  }

  p, h1, h2, h3, h4, span, li, a {
    color: ${({ theme }) => theme.text};
    transition: color 0.15s linear;
  }

  li, ul {
    list-style:none;
    margin: 0;
    padding: 0;
  }

  body.modal-open{
    overflow: hidden;
  }
`;
