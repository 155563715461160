import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: none;
  align-items: center;
  justify-items: center;

  @media (max-width: 750px) {
    display: block;
  }
`;
